import { Button, Table } from 'react-bootstrap';
import AppPage from './AppPage';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { apiCall, errorHandler } from '../api/Api';
import { toast } from 'react-toastify';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { User } from '../types';
import { QUERY_PARTNERS } from '../api/queries';
import { useAuth } from '../context/auth';
import { addPartner, deletePartner, getPartners } from '../api/PartnersApi';
import { PartnerModal } from '../modals/PartnerModal';

export default function PartneriPage() {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  const auth = useAuth();

  useEffect(() => {
    if (auth?.role && auth.role !== 'ADMIN') {
      // TODO provést redirect na login!
    }
  }, [auth])

  const { isLoading, data } = useQuery({
    queryKey: [QUERY_PARTNERS],
    queryFn: getPartners,
  })
  const queryClient = useQueryClient();

  const handleClose = () => {
    setModalIsOpen(false);
  }

  const handleSave = async (data: User) => {
    try {
      await errorHandler(() => addPartner(data));
      // TODO chceme spíše zobrazit informaci, že byl odeslán email s odkazem na nastavení hesla
      toast.success("Partner uložen", { theme: "colored" });
      setModalIsOpen(false);
      queryClient.invalidateQueries({ queryKey: [QUERY_PARTNERS] })
    } catch (e: any) {
      // Není potřeba nic dělat
    }
  }

  const handleDelete = async (partner: User) => {
    confirmAlert({
      title: 'Smazání partnera',
      message: `Opravdu si přejete odstranit partnera s přihlašovacím jménem ${partner.login}? Pozor, tato akce automaticky odstraní veškerá data partnera, včetně klientů, notifikací a produktů!`,
      buttons: [
        {
          label: 'Smazat',
          onClick: async () => {
            try {
              await apiCall(() => deletePartner(partner.id));
              toast.success("Partner odstraněn", { theme: "colored" });
              setModalIsOpen(false);
              queryClient.invalidateQueries({ queryKey: [QUERY_PARTNERS] })
            } catch (e: any) {
              // Není potřeba nic dělat
            }
          }
        },
        {
          label: 'Storno',
        }
      ]
    });
  }

  const renderTable = () => {
    if (!data?.length) {
      return <p>Přidejte partnera tlačítkem "Nový"</p>
    }
    return <Table striped bordered hover>
      <thead>
        <tr>
          <th>Login</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {data?.map(partner =>
          <tr key={partner.login}>
            <td>{partner.login}</td>
            <td style={{ whiteSpace: 'nowrap' }}>
              <FontAwesomeIcon icon={faTrash} title='Odstranit' className='action-icon' onClick={() => handleDelete(partner)} />
            </td>
          </tr>)}
      </tbody>
    </Table>
  }

  // TODO hezčí načítání
  if (isLoading) return <p>Načítám data...</p>

  return <AppPage>
    <>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <h1>Partneři</h1>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Button onClick={() => setModalIsOpen(true)} style={{ alignSelf: 'flex-end', marginBottom: '20px' }}><FontAwesomeIcon icon={faPlus} /> Nový</Button>
          <p>Počet záznamů: {data?.length}</p>
          {renderTable()}
        </div>
      </div>
      {modalIsOpen && <PartnerModal title={"Nový partner"} isOpen={modalIsOpen} onClose={handleClose} onSave={handleSave} />}
    </>
  </AppPage>
}
