import { User } from "../types";
import { api } from "./Api";

const BASE_PATH = '/api/partners';

export const getPartners = async () => {
    return await api.get<User[]>(`${BASE_PATH}/get`);
}

export const addPartner = async (data: User) => {
    return await api.post<any, any>(`${BASE_PATH}/add`, JSON.stringify(data));
}

export const deletePartner = async (id?: number) => {
    return await api.delete<any>(`${BASE_PATH}/delete?id=${id}`);
}