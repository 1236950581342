import { toast } from "react-toastify";
import { getBaseUrl, getToken } from "../Utils";

/**
 * Wrapper pro volání API, u kterých chceme automaticky zobrazit toaster s chybou ze serveru.
 * 
 * @param apiFunction volaná API funkce
 */
export function errorHandler<T>(apiFunction: () => Promise<T>): Promise<T> {
    return new Promise<T>((resolve, reject) => {
        apiFunction().then((result) => {
            resolve(result);
        }).catch(e => {
            toast.error(e.message, { theme: "colored" });
        });
    });
}

async function request<TResponse>(
    url: string,
    config: RequestInit = {}
): Promise<TResponse> {
    config.headers = config?.headers ? new Headers(config.headers) : new Headers();
    config.headers.set("Authorization", `Bearer ${getToken()}`);
    try {
        const response = await fetch(getBaseUrl() + url, config);
        if (!response.ok) {
            const json = await response.json();
            // Vyhodíme samotnou hlášku z odpovědi, odchytí si jí errorHandler
            throw new Error(json.error);
        }
        return response.json() as TResponse;
    } catch (e) {
        return Promise.reject(e);
    }
}

export const api = {
    get: <TResponse>(url: string) => request<TResponse>(url),
    delete: <TResponse>(url: string) => request<TResponse>(url, { method: 'DELETE' }),
    post: <TBody extends BodyInit, TResponse>(url: string, body: TBody) => request<TResponse>(url, { method: 'POST', body, headers: { 'Content-Type': 'application/json' } }),
}

// TODO zrušit a nahradit za errorHandler
export const apiCall = async (call: Function, ...args: any[]) => {
    try {
        return await call(...args);
    } catch (e: any) {
        toast.error((e as Error).message, { theme: "colored" });
        throw Error('Chyba při volání API');
    }
}