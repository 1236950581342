import { Button, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { User } from "../types";

type Props = {
    title: string,
    isOpen: boolean,
    onClose?: () => void,
    onSave: (data: User) => void,
}

export function PartnerModal({ title, isOpen, onClose, onSave }: Props) {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<User>()

    return <Modal show={isOpen} onHide={onClose} size="lg">
        <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form onSubmit={handleSubmit(data => onSave(data))}>
                <Form.Control placeholder='Jméno' {...register("jmeno", { required: true })} />
                {errors.jmeno && <span className='error'>Pole je povinné</span>}
                <Form.Control placeholder='Příjmení' {...register("prijmeni", { required: true })} />
                {errors.prijmeni && <span className='error'>Pole je povinné</span>}
                <Form.Control placeholder='Telefon' {...register("telefon", { required: true })} />
                {errors.telefon && <span className='error'>Pole je povinné</span>}
                <Form.Control placeholder='E-mail' {...register("login", { required: true })} />
                {errors.login && <span className='error'>Pole je povinné</span>}
                <p style={{ marginTop: '20px' }}>Na zadanou e-mailovou adresu bude odeslán e-mail s vygenerovaným heslem a instrukcemi pro jeho změnu.</p>
            </Form>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={onClose}>
                Storno
            </Button>
            <Button variant="primary" onClick={handleSubmit(data => onSave(data))}>
                Uložit
            </Button>
        </Modal.Footer>
    </Modal>
}