import { Button, Table } from 'react-bootstrap';
import AppPage from './AppPage';
import { useMemo, useState } from 'react';
import { ProductModal } from '../modals/ProductModal';
import { toast } from 'react-toastify';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { faPencil, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { confirmAlert } from 'react-confirm-alert';
import { addProduct, deleteProduct, editProduct, getProducts } from '../api/ProductsApi';
import { apiCall } from '../api/Api';
import { ProduktForm, SortConfig, TypNotifikace } from '../types/Types';
import { QUERY_PRODUCTS } from '../api/queries';
import { filterSort, getSortableHeaderClassName } from '../Utils';
import { useAuth } from '../context/auth';

export default function ProduktyPage() {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>('Nový produkt');
  const [initialValues, setInitialValues] = useState<ProduktForm>();
  const [sortConfig, setSortConfig] = useState<SortConfig<ProduktForm>>();

  const auth = useAuth();

  const { isLoading, data } = useQuery({
    queryKey: [QUERY_PRODUCTS],
    queryFn: getProducts,
  })
  const queryClient = useQueryClient();

  const products = useMemo(() => filterSort(data, undefined, undefined, sortConfig), [data, sortConfig]);

  const handleClose = () => {
    if (window.confirm("Veškeré neuložené změny budou ztraceny!")) {
      setModalIsOpen(false);
    }
  }

  const handleSave = async (data: ProduktForm, initialValues?: ProduktForm) => {
    try {
      if (initialValues) {
        await apiCall(() => editProduct(data, initialValues.nazev));
      } else {
        await apiCall(() => addProduct(data));
      }
      toast.success("Produkt uložen", { theme: "colored" });
      setModalIsOpen(false);
      queryClient.invalidateQueries({ queryKey: [QUERY_PRODUCTS] })
    } catch (e: any) {
      // Není potřeba nic dělat
    }
  }

  const handleCreate = () => {
    setModalTitle("Nový produkt");
    setInitialValues(undefined);
    setModalIsOpen(true);
  }

  const handleEdit = (product: ProduktForm) => {
    setModalTitle("Úprava produktu");
    setInitialValues(product);
    setModalIsOpen(true);
  }

  const handleDelete = async (product: ProduktForm) => {
    confirmAlert({
      title: 'Smazání produktu',
      message: `Opravdu si přejete odstranit produkt ${product.nazev}?`,
      buttons: [
        {
          label: 'Smazat',
          onClick: async () => {
            try {
              await apiCall(() => deleteProduct(product.nazev));
              toast.success("Produkt odstraněn", { theme: "colored" });
              setModalIsOpen(false);
              queryClient.invalidateQueries({ queryKey: [QUERY_PRODUCTS] })
            } catch (e: any) {
              // Není potřeba nic dělat
            }
          }
        },
        {
          label: 'Storno',
        }
      ]
    });
  }

  // TODO duplicita, vytrhnout
  const handleSort = (key: keyof ProduktForm) => {
    if (sortConfig == null) {
      setSortConfig({ key: key, direction: 'ascending' });
      return;
    }
    let newSortConfig = { ...sortConfig };
    if (key === sortConfig?.key) {
      newSortConfig.direction = newSortConfig.direction === 'ascending' ? 'descending' : 'ascending';
    }
    newSortConfig.key = key;
    setSortConfig(newSortConfig);
  }

  const renderTable = () => {
    if (!data?.length) {
      return <p>Přidejte první produkt tlačítkem "Nový"</p>
    }
    return <Table striped bordered hover>
      <thead>
        <tr>
          <th className={getSortableHeaderClassName(sortConfig, 'nazev')} onClick={() => handleSort('nazev')}>Název</th>
          {Object.entries(TypNotifikace).map(entry => <th key={entry[0]}>Notifikace {entry[1]}</th>)}
          {/* TODO konstanta */}
          {auth?.role === 'ADMIN' ? <th></th> : undefined}
        </tr>
      </thead>
      <tbody>
        {products?.map(product =>
          <tr key={product.nazev}>
            <td>{product.nazev}</td>
            {Object.entries(TypNotifikace).map(entry => {
              const key = `notifikace_${entry[0].toLowerCase()}`;
              return <td key={entry[0]}>{product[key]}</td>
            })}
            {/* TODO konstanta! */}
            {auth?.role === 'ADMIN' ? <td>
              <FontAwesomeIcon icon={faPencil} title='Upravit' className='action-icon' onClick={() => handleEdit(product)} />
              <FontAwesomeIcon icon={faTrash} title='Odstranit' className='action-icon' onClick={() => handleDelete(product)} />
            </td> : undefined}
          </tr>
        )}
      </tbody>
    </Table>
  }

  // TODO hezčí načítání
  if (isLoading) return <p>Načítám data...</p>

  return <AppPage>
    <>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <h1>Produkty</h1>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {/* TODO konstanta */}
          {auth?.role === 'ADMIN' ? <Button onClick={handleCreate} style={{ alignSelf: 'flex-end', marginBottom: '20px' }}><FontAwesomeIcon icon={faPlus} /> Nový</Button> : undefined}
          <p>Počet záznamů: {data?.length}</p>
          {renderTable()}
        </div>
      </div>
      {modalIsOpen && <ProductModal title={modalTitle} isOpen={modalIsOpen} onClose={handleClose} onSave={handleSave} initialValues={initialValues} />}
    </>
  </AppPage>
}
