import { useEffect } from 'react';
import { useAuth } from '../context/auth';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import AppPage from './AppPage';
import { login } from '../api/AuthApi';
import './LoginPage.scss';
import { errorHandler } from '../api/Api';

type Inputs = {
  login: string
  password: string
}

/**
 * Formulář pro prvotní zadání přihlašovacího jména.
 */
export default function LoginPage() {
  const auth = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>()
  const navigate = useNavigate();

  useEffect(() => {
    if (auth?.login) {
      navigate('/');
    }
  }, [auth?.login, navigate]);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const token = await errorHandler(() => login(data.login, data.password));;
    if (token) {
      auth?.setToken(token);
    }
  }

  if (!auth || !auth.login) {
    return <AppPage>
      <div className='login'>
        <h1>ClientHub</h1>
        <div className='login-inner'>
          <form onSubmit={handleSubmit(onSubmit)}>
            <input autoComplete='username' placeholder='Uživatelské jméno' {...register("login", { required: true })} />
            {errors.login && <span className='error'>Pole je povinné</span>}
            <input autoComplete='current-password' type='password' placeholder='Heslo' {...register("password", { required: true })} style={{ marginTop: '10px' }} />
            {errors.password && <span className='error'>Pole je povinné</span>}
            <Button type='submit' style={{ marginTop: "20px" }}>Přihlásit se</Button>
          </form>
        </div>
      </div>
    </AppPage>
  }
  return <div>Neplatný stav</div>
}
